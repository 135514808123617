import React from 'react';
import './InfoMenu.css'; 
const InfoMenu = ({ closeMenu }) => {
  return (
    <div className="info-menu-overlay" onClick={closeMenu}>
      <div className="info-menu-content" onClick={(e) => e.stopPropagation()}>
        <h2>Info Menu</h2>
        <p>This is the information menu content.</p>
        <button className="btn-close" onClick={closeMenu}>Close Menu</button>
      </div>
    </div>
  );
};

export default InfoMenu;