import React from 'react';
import './InformationSection.css'; 

const InformationSection = ({ onClose, content, buttonText }) => {
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={handleContentClick}>
        <div className="close-btn" onClick={onClose}>
          <img src={require('../images/icons/close.png')} alt="Close" className="close-icon"/>
        </div>
        
        {/* Dynamic content */}
        <div className="popup-info">
          <p>{content}</p>
        </div>
        
        {/* Main action button */}
        <button  className="btn-accept" onClick={onClose}>{buttonText}</button>
      </div>
    </div>
  );
};

export default InformationSection;

