import React, { useState } from 'react';
import './CharacterSelection.css';
import infoIcon from '../images/icons/info-icon.png';
import InformationSection from './InformationSection'; 
import { addCharacterToPlayer,getPlayer } from '../api/playersApi';

const CharacterSelection = ({ onCharacterSelected, onPlayGame, player }) => {

  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupButtonText, setPopupButtonText] = useState('');

  const handleCharacterClick = (character) => {
    setSelectedCharacter(character);
  };

  const handleInfoClick = () => {
    setPopupContent('Here is some detailed information about the characters.');
    setPopupButtonText(`LET'S GO`);
    setShowPopup(true);
  };

  const handleClosePopup = () => setShowPopup(false);

 const handleSelectClick = async () => {
  if (selectedCharacter) {
    console.log('selectedCharacter:', selectedCharacter);
    try {
      await addCharacterToPlayer(player.telegram_id, selectedCharacter);
      const updatedPlayerData = await getPlayer(player.telegram_id);
      onCharacterSelected(updatedPlayerData.character);
      onPlayGame();
    } catch (error) {
      console.error('Помилка при додаванні персонажа:', error);
    }
  }
};





  return (
    <div className="page">
      <div className="container">
        <div className="character-selection-wrapper">
          <div className="info-left-icon" onClick={handleInfoClick} style={{ cursor: 'pointer' }}>
            <img src={infoIcon} alt="Info Icon" className="info-icon" />
          </div>
          <div className="select-character-wrapper">
            <div className="character-item-grid">
              <div
                className={`pet-item main ${selectedCharacter === 'main' ? 'selected' : ''}`}
                onClick={() => handleCharacterClick('main')}
              ></div>
              <div
                className={`pet-item pet1 ${selectedCharacter === 'pet1' ? 'selected' : ''}`}
                onClick={() => handleCharacterClick('pet1')}
              ></div>
              <div
                className={`pet-item pet2 ${selectedCharacter === 'pet2' ? 'selected' : ''}`}
                onClick={() => handleCharacterClick('pet2')}
              ></div>
              <div
                className={`pet-item pet3 ${selectedCharacter === 'pet3' ? 'selected' : ''}`}
                onClick={() => handleCharacterClick('pet3')}
              ></div>
              <div
                className={`pet-item pet4 ${selectedCharacter === 'pet4' ? 'selected' : ''}`}
                onClick={() => handleCharacterClick('pet4')}
              ></div>
            </div>
            <div className="action-select-wrapper">
              <div className="action-left-arrow"></div>
              <button className="btn-accept" onClick={handleSelectClick}>
                Select
              </button>
              <div className="action-left-arrow"></div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <InformationSection
          onClose={handleClosePopup} 
          content={popupContent} 
          buttonText={popupButtonText} 
        />
      )}
    </div>
  );
};

export default CharacterSelection;
