import React, { useState } from 'react';
import './Start.css';
import InformationSection from './InformationSection';
import infoIcon from '../images/icons/info-icon.png';


function Start({ onPlay }) {
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
  const [popupContent, setPopupContent] = useState(''); // State to manage content in the popup
  const [buttonText, setButtonText] = useState(''); // State to manage button text in the popup


  // Function to open the popup with dynamic content and button text
  const handleInfoClick = () => {
    setPopupContent
    (
      <div>
        <h4>How to become the coolest POOMBY CO-OWNER?</h4>
        <p>Subscribe and invite your friends to the game right now!</p>
        <p>You can receive numerous bonuses in the form of exciting characters, unique add-ons, game bonuses, a lot of coins and real tokens that other users won't have!!</p>
        <p>This offer is only available for users who start playing our game right now!.</p>
      </div>
    );
    setButtonText('COOL');
    setShowPopup(true);
  };


  // Function to close the popup
  const handleClosePopup = () => setShowPopup(false);


  return (
    <div className="page">
      <div className="container">
        <div className="start-page-wrapper">
          <div className="subscribe-wrapper">
            <div className="icon" onClick={handleInfoClick}>
              <img src={infoIcon} alt="Info Icon" className="info-icon" />
            </div>
            <div className="subscribe-content">
              <h4>"IN POOMB WE TRUST, IN POOMB WE EARN"</h4>
              <p>What's $COGA TOKEN?</p>
              <p>
                Comet Games token (COGA) is a native token created by gamedev company Comet Space
                and will be integrated into all Comet Space company projects.
                The first project with token integration is Poomb Creatures.
              </p>
              <p>What's POOMB Creatures?</p>
              <p>
                It' s a whole ecosystem of Telegram clicker, NFT,
                Crypto exchange and mobile game for the iOS Store and
                Google Play Market platforms that combines popular elements of the
                Tamagotchi genre with advanced blockchain technologies.
              </p>
              <p>So, awaken your inner POOMB!
                Choose your Poomb Pet and start earning $COGA TOKEN!!
              </p>
            </div>
            {/* <button className="btn-action">Subscribe</button> */}
            <button
                className="btn-action"
                onClick={() => window.open('https://t.me/poomb_game/44', '_blank')}>
                Subscribe
            </button>
          </div>
          <div className="action-wrapper">
            <button className="btn-accept" onClick={onPlay}>
              Play
            </button>
          </div>
        </div>
      </div>
     
      {/* Full-screen popup for Info with dynamic content and button */}
      {showPopup && (
        <InformationSection
          onClose={handleClosePopup}
          content={popupContent}
          buttonText={buttonText}
        />
      )}
    </div>
  );
}


export default Start;