import React, { useState, useEffect } from 'react';
import './Loader.css';
import logo from '../images/logo.png';

function Loader({ onComplete }) {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  
  

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress + 1;
        if (newProgress >= 100) {
          clearInterval(interval);
          setLoading(false);
          onComplete(); 
        }
        return newProgress;
      });
    }, 30);


    return () => clearInterval(interval);
  }, [onComplete]);

  if (loading) {
    return (
      <div className="page">
        <div className="container">
          <div className="loader-wrapper">
            <img src={logo} className="logo" alt="Logo" />
            <div className="loading-progress-bar">
              <div
                className="loading-progress"
                style={{
                  width: `${progress}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export default Loader;
