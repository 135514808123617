import React from 'react';
import './AchieveMenu.css'; 

const AchieveMenu = ({ closeMenu }) => {
  return (
    <div className="achieve-menu-overlay" onClick={closeMenu}>
      <div className="achieve-menu-content" onClick={(e) => e.stopPropagation()}>
        <h2>Achievements Menu</h2>
        <p>This is the achievements content. It covers most of the screen.</p>
        <button className="btn-close" onClick={closeMenu}>Close Menu</button>
      </div>
    </div>
  );
};

export default AchieveMenu;