import React, { useEffect, useState } from 'react';
import Loader from './components/Loader';
import Start from './components/Start';
import CharacterSelection from './components/CharacterSelection';
import Gameplay from './components/Gameplay';
import './App.css';
import { getPlayer } from './api/playersApi'; // Імпорт функції

const App = () => {
  const [currentPage, setCurrentPage] = useState('loading');
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [playerData, setPlayerData] = useState(null);
  console.log('playerData:', playerData);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      const user = window.Telegram.WebApp.initDataUnsafe?.user;
      if (user) {
        setUserId(user.id);
      } else {
        const mockUser = { id: 13, first_name: 'TestUser', username: 'test_user' };
        setUserId(mockUser.id);
      }
    }
  }, []);

  const fetchPlayerData = async () => {
    if (userId) {
      try {
        const data = await getPlayer(userId);
        setPlayerData(data);
      } catch (error) {
        console.error('Помилка при завантаженні даних гравця:', error);
      }
    }
  };

  useEffect(() => {
    fetchPlayerData();
  }, [userId]);

  const handleLoaderComplete = () => {
    setCurrentPage('start');
  };

  const handlePlay = async () => {
    try {
      await fetchPlayerData();
      if (playerData && playerData.character) {  
        setSelectedCharacter(playerData.character);
        setCurrentPage('gameplay');
      } else {
        setCurrentPage('character-selection');
      }
    } catch (error) {
      console.error('Помилка при завантаженні персонажа:', error);
    }
  };

  const handleCharacterSelect = async (character) => {
    setSelectedCharacter(character);
  };

  return (
    <div className="App">
      {currentPage === 'loading' && <Loader onComplete={handleLoaderComplete} />}
      {currentPage === 'start' && <Start onPlay={handlePlay} />}
      {currentPage === 'character-selection' && (
        <CharacterSelection
          player={playerData}
          onCharacterSelected={handleCharacterSelect}
          onPlayGame={() => setCurrentPage('gameplay')}
        />
      )}
      {currentPage === 'gameplay' && <Gameplay player={playerData} selectedCharacter={selectedCharacter} />} 
    </div>
  );
};

export default App;
