import React from 'react';
import './LevelInfo.css'; 

const LevelInfo = ({ closePopup }) => {
  return (
    <div className="level-info-overlay">
        <div className="level-info-content">
            <div className="center-block">
                <div className="level-item">
                    <div className="account"></div>
                    <p>4 LVL</p>
                </div>
            </div>
            <div className="level-bar-wrapper">
                <div className="progress-level-bar">
                    <div className="progress"></div>
                </div>
                <p>Tap to increase Poomb level</p>
            </div>
            <div className="rank-wrapper">
                <button className="rank-btn">[-]</button>
                <div className="rank-info-wrapper">
                    <p>rang 124</p>
                    <div className="leaderboard-wrapper">
                        <p>leaderboard and achievements</p>
                        <p id="openPopup1">☮</p>
                    </div>
                </div>
            </div>
            <div className="information-wrapper">
                <p>Don't miss the chance to GET up to 150 000 $COGA TOKENS inviting your friends</p>
            </div>
            <div className="friends-wrapper">
                <p>5 friend</p>
                <div className="btn-invite-wrapper">
                    <button className="invite-btn">INVITE FRIENDS</button> 
                    <p id="openPopup2">☮</p>
                </div>
            </div>
            <button className="btn-close" onClick={closePopup}>Close</button>
        </div>
    </div>
  );
};

export default LevelInfo;