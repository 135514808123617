import React, { useState, useEffect } from 'react';
import './Gameplay.css';
import LevelInfo from './LevelInfo';
import InfoMenu from './InfoMenu'; 
import AchieveMenu from './AchieveMenu'; 
import { getCharacterById, updatePlayerClicks } from '../api/playersApi'; // Імпорт функції для запиту
import fullIcon from '../images/icons/capi-storage.png';

const Gameplay = ({ player, selectedCharacter }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isInfoMenuVisible, setInfoMenuVisible] = useState(false);
  const [isAchieveMenuVisible, setAchieveMenuVisible] = useState(false);
  const [isCharacterClicked, setIsCharacterClicked] = useState(false);
  const [coins, setCoins] = useState(0); // State for the coin count, starting from 0
  const [energy, setEnergy] = useState(35); // State for energy, starting from 35
  const [characterData, setCharacterData] = useState(null);

  useEffect(() => {
    const fetchCharacterData = async () => {
      if (selectedCharacter) {
        try {
          const data = await getCharacterById(selectedCharacter);
          setCharacterData(data);
        } catch (error) {
          console.error('Помилка при завантаженні персонажа:', error);
        }
      }
    };

    fetchCharacterData();
  }, [selectedCharacter]);

  // Об'єднана функція для обробки кліку на персонажа
  const handleCharacterClick = async () => {
    if (energy === 0) return; // Якщо енергія 0, не робити нічого

    // Анімація кліку на персонажа
    setIsCharacterClicked(true);

    // Збільшуємо кількість монет на 1
    setCoins(coins + 1);

    // Зменшуємо кількість енергії на 1
    setEnergy((prevEnergy) => Math.max(prevEnergy - 1, 0));

    try {
      const clickCount = 1;
      // Оновлюємо кількість кліків на сервері
      await updatePlayerClicks(player.telegram_id, clickCount);
      console.log(`Кількість кліків: ${clickCount}`);
    } catch (error) {
      console.error('Помилка при оновленні кількості кліків:', error);
    }

    // Скидання анімації через 300 мс
    setTimeout(() => {
      setIsCharacterClicked(false);
    }, 300);
  };

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const toggleInfoMenu = () => {
    setInfoMenuVisible(!isInfoMenuVisible);
  };

  const toggleAchieveMenu = () => {
    setAchieveMenuVisible(!isAchieveMenuVisible);
  };

  return (
    <div className="page">
      <div className="container">
        <div className="gameplay-wrapper">
          <div className="player-info">
            <div className="level-item">
              <div className="account-wrapper" onClick={togglePopup}></div>
              <p>{player.lvl} LVL</p>
            </div>
            <div className="progress-table-wrapper">
              <img src={fullIcon} alt="Full Image" className=""/>
            </div>
          </div>
          <div className="game-field-wrapper">
            <div className="coins-energy-wrapper">
              <p>{energy}/35</p>
            </div>
            <div className="selected-character-display" onClick={handleCharacterClick}>
              {characterData ? (
                <div className={`character ${characterData.image_url}`} />
              ) : (
                <p>No character selected</p>
              )}
              <p>{coins.toLocaleString()} Coins</p> {/* Виводимо кількість монет */}
            </div>

            <div className="energy-wrapper">
              <p>{energy} Energy</p> {/* Виводимо кількість енергії */}
            </div>
          </div>

          <div className="bottom-info">
            <button className="btn" onClick={toggleInfoMenu}>Menu Short</button>
            <button className="btn" onClick={toggleAchieveMenu}>Menu Long</button>
          </div>
        </div>
      </div>

      {isPopupVisible && <LevelInfo closePopup={togglePopup} />}
      {isInfoMenuVisible && <InfoMenu closeMenu={toggleInfoMenu} />}
      {isAchieveMenuVisible && <AchieveMenu closeMenu={toggleAchieveMenu} />}
    </div>
  );
};

export default Gameplay;