import axios from '../utils/axios'

export const createPlayer = async (telegram_id, referral_code) => {
  const { data } = await axios.post(`/players/create`, {
    telegram_id,
    referral_code,
  });
  return data;
};

export const addCharacterToPlayer = async (telegram_id, image_url) => {
console.log('image_url:', image_url)
const { data } = await axios.post(`/players/player_add_character`, {
telegram_id,
image_url,
});
return data;

};

export const updatePlayerClicks = async (telegram_id, clicks_count) => {
  const { data } = await axios.post(`/players/${telegram_id}/click`, {
    clicks_count,
  });
  return data;
};

export const getPlayer = async (telegram_id) => {
  const { data } = await axios.get(`/players/${telegram_id}`);
  return data;
};

export const getCharacterById = async (character_id) => {
  const { data } = await axios.get(`/characters/${character_id}`);
  return data;
};

